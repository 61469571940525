import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes, Navigate } from "react-router-dom";
import "./App.css";
import { inject } from "@vercel/analytics";
import { injectSpeedInsights } from "@vercel/speed-insights";
import SignUpAndLogin from "./components/signupLogin";
import Home from "./components/Home";
import Profile from "./components/Profile";
import Highschool from "./Careers/Highschool";
import {Footer} from "./components/headerFooter";
import CareerOptions from "./Careers/Common/CareerOptions";
import ResetPasswordScreen from "./components/ResetPasswordScreen";


function App() {

  useEffect(() => {
    inject();
    injectSpeedInsights();
  }, []);

  const isAuthenticated = () => {
    return localStorage.getItem('token') !== null;
  };

  const ProtectedRoute = ({ children }) => {
    if (!isAuthenticated()) {
      return <Navigate to="/" replace />;
    }
    return children;
  };

  return (
    <Router>
      <Routes>
        <Route path="/bro" element={isAuthenticated() ? <Navigate to="/home" replace /> : <SignUpAndLogin />} />
        <Route path='/home' element={<ProtectedRoute><Home/></ProtectedRoute>}/>
        <Route path='/profile' element={<ProtectedRoute><Profile/></ProtectedRoute>}/>
        <Route path='/highschool' element={<ProtectedRoute><Highschool/></ProtectedRoute>}/>
        <Route path='/careeroptions' element={<ProtectedRoute><CareerOptions/></ProtectedRoute>}/>
        <Route path="/reset/:token" element={<ResetPasswordScreen />} />
        <Route path="*" element={<Navigate to="/bro" replace />} />
      </Routes>
      <Footer/>
    </Router>
  );
}

export default App;
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { HashLoader } from "react-spinners";
import "./signupLogin.css";
import { Footer } from "./headerFooter";

const SignUpAndLogin = () => {
  const [isLogin, setIsLogin] = useState(true);
  const [username, setUsername] = useState("");
  const [fullName, setFullName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [errors, setErrors] = useState({});
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useNavigate();

  const validatePassword = (password) => {
    const regex = /^(?=.*[A-Z])(?=.*\d)(?=.*[!@#$%^&*])[A-Za-z\d!@#$%^&*]{8,}$/;
    return regex.test(password);
  };

  const validateMobile = (mobile) => {
    return /^\d{10}$/.test(mobile);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const newErrors = {};
    
    if (!isLogin) {
      if (!validatePassword(password)) {
        newErrors.password =
          "Password must contain at least 8 characters, including one uppercase letter, one number, and one special character.";
      }
      if (!validateMobile(mobile)) {
        newErrors.mobile = "Mobile number must be exactly 10 digits.";
      }
    }

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }
    setIsLoading(true); // Start Loading

    const apiUrl = isLogin
      ? "https://careerxpo.vercel.app/api/users/login"
      : "https://careerxpo.vercel.app/api/users/signup";
    const payload = isLogin
      ? { username, password }
      : {
          username,
          fullName,
          password,
          confirmPassword,
          mobile,
          email
        };
    try {
      const response = await axios.post(apiUrl, payload);

      // Store user information in local storage
      localStorage.setItem("user", JSON.stringify(response.data.user));
      localStorage.setItem("token", response.data.token);

      navigate("/home");

      // Clear form fields
      setUsername("");
      setPassword("");
      if (!isLogin) {
        setFullName("");
        setMobile("");
        setEmail("");
        setConfirmPassword("");
      }
    } catch (err) {
      console.error(err);
      setErrors({
        general:
          err.response?.data?.msg || "An error occurred during authentication.",
      });
    } finally {
      setIsLoading(false);
    }
  };

  const handleMobileChange = (e) => {
    const value = e.target.value.replace(/\D/g, "").slice(0, 10);
    setMobile(value);
    if (value.length !== 10) {
      setErrors({
        ...errors,
        mobile: "Mobile number must be exactly 10 digits.",
      });
    } else {
      setErrors({ ...errors, mobile: "" });
    }
  };

  return (
    <div className="landing-container">
      <div className={`form-container ${isLogin ? "login" : "signup"}`}>
        <div className="form-header">
          <h2>{isLogin ? "Welcome Back" : "Create New Account"}</h2>
        </div>
        <div className="toggle-container">
          <div
            className={`toggle ${isLogin ? "login" : "signup"}`}
            onClick={() => setIsLogin(!isLogin)}
          >
            <span className="toggle-button"></span>
          </div>
          <span className="toggle-label">{isLogin ? "Login" : "Signup"}</span>
        </div>
        <form onSubmit={handleSubmit}>
          <div className="input-row">
            <div className="input-group">
              <input
                type="text"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required
              />
              <label>Username</label>
            </div>
            {!isLogin && (
              <div className="input-group">
                <input
                  type="text"
                  value={fullName}
                  onChange={(e) => setFullName(e.target.value)}
                  required
                />
                <label>Full Name</label>
              </div>
            )}
          </div>
          <div className="input-group">
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              required
            />
            <label>Password</label>
            {errors.password && (
              <span className="error">{errors.password}</span>
            )}
          </div>
          {!isLogin && (
            <div className="input-group">
              <input
                type="password"
                value={confirmPassword}
                onChange={(e) => setConfirmPassword(e.target.value)}
                required
              />
              <label>Confirm Password</label>
              {errors.confirmPassword && (
                <span className="error">{errors.confirmPassword}</span>
              )}
            </div>
          )}
          {!isLogin && (
            <>
              <div className="input-row">
                <div className="input-group">
                  <input
                    type="tel"
                    value={mobile}
                    onChange={handleMobileChange}
                    maxLength="10"
                    required
                  />
                  <label>Mobile Number</label>
                  {errors.mobile && (
                    <span className="error">{errors.mobile}</span>
                  )}
                </div>
              </div>
            </>
          )}
          {!isLogin && (
              <div className="input-group">
                <input
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  required
                />
                <label>Email</label>
              </div>
            )}
          <button type="submit" className="submit-btn">
            {isLogin ? "Login" : "Sign Up"}
          </button>
        </form>
        {errors.general && (
          <p className="error general-error">{errors.general}</p>
        )}
        {/* {isLogin && <p className="forgot-password">Forgot password?</p>} */}
        <p className="switch-mode">
          {isLogin ? "Don't have an account? " : "Already have an account? "}
          <span onClick={() => setIsLogin(!isLogin)}>
            {isLogin ? "Sign Up" : "Login"}
          </span>
        </p>
      </div>
      <Footer/>
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "transparent",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <HashLoader color="#ff8200" loading={isLoading} size={50} />
        </div>
      )}
    </div>
  );
};

export default SignUpAndLogin;

import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Header } from "../../components/headerFooter";
import { Button } from "@cred/neopop-web/lib/components";
import axios from "axios";
import { HashLoader } from "react-spinners";
import "./CareerOptions.css";

const CombinedCareerOptions = () => {
  const [selectedCareer, setSelectedCareer] = useState(null);
  const [careerData, setCareerData] = useState({});
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const stream = location.state?.stream || "Maths";

  useEffect(() => {
    fetchCareerData();
  }, [stream]);

  const fetchCareerData = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `https://careerxpo.vercel.app/api/users/careers?category=${stream.toLowerCase()}CareerData`
      );
      const fetchedData = response.data.reduce((acc, item) => {
        acc[item.degree] = item;
        return acc;
      }, {});
      setCareerData(fetchedData);
    } catch (error) {
      console.error(
        "Error fetching career data:",
        error.response ? error.response.data : error.message
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleCareerClick = (career) => {
    setSelectedCareer(career);
  };

  const careerOptions = Object.keys(careerData);

  return (
    <div className="career-options-container">
      <Header subject={stream} />
      <div className="main-content">
        {!selectedCareer ? (
          <div className="career-grid">
            {careerOptions.map((option, index) => (
              <Button
                variant="secondary"
                kind="elevated"
                size="big"
                key={index}
                onClick={() => handleCareerClick(option)}
              >
                {option}
              </Button>
            ))}
          </div>
        ) : (
          <CareerDetails
            career={selectedCareer}
            data={careerData[selectedCareer]}
            onBack={() => setSelectedCareer(null)}
          />
        )}
      </div>
      {isLoading && (
        <div className="loading-overlay">
          <HashLoader color="#ff8200" loading={isLoading} size={50} />
        </div>
      )}
    </div>
  );
};

const CareerDetails = ({ career, data, onBack }) => {
  const renderList = (items) => {
    if (typeof items === 'string') {
      return items.split(',').map((item, index) => (
        <li key={index}>{item.trim()}</li>
      ));
    }
    return null;
  };

  const renderColleges = (colleges) => {
    if (typeof colleges === 'string') {
      return colleges.split(';').map((college, index) => {
        const [name, url] = college.split('(');
        return (
          <li key={index}>
            {url ? (
              <a href={url.slice(0, -1)} target="_blank" rel="noopener noreferrer">
                {name.trim()}
              </a>
            ) : (
              name.trim()
            )}
          </li>
        );
      });
    }
    return null;
  };

  const renderFees = (fees) => {
    if (typeof fees === 'string') {
      try {
        const feesObject = JSON.parse(fees.replace(/'/g, '"'));
        return Object.entries(feesObject).map(([institution, fee], index) => (
          <li key={index}>
            <strong>{institution}:</strong> {fee}
          </li>
        ));
      } catch (error) {
        return <li>{fees}</li>;
      }
    }
    return null;
  };

  return (
    <div className="career-details">
      <Button
        variant="secondary"
        kind="elevated"
        size="medium"
        onClick={() => {
          setTimeout(onBack, 400);
        }}
      >
        &larr; Back to all careers
      </Button>

      <div className="career-card">
        <div className="career-card-header">
          <h2>{career}</h2>
        </div>
        <div className="career-card-content">
          <p>{data.description}</p>
          <hr />
          <h3>Key Details</h3>
          <ul>
            <li><strong>Duration:</strong> {data.duration}</li>
            <li><strong>Eligibility:</strong> {data.eligibility}</li>
          </ul>
          <hr />
          <h3>Entrance Exams</h3>
          <ul>{renderList(data.entranceExams)}</ul>
          <hr />
          <h3>Top Colleges</h3>
          <ul>{renderColleges(data.topColleges)}</ul>
          <hr />
          <h3>Career Prospects</h3>
          <ul>{renderList(data.careerProspects)}</ul>
          <hr />
          <h3>Fees</h3>
          <ul>{renderFees(data.fees)}</ul>
          <hr />
          <h3>Scholarships</h3>
          <ul>{renderColleges(data.scholarships)}</ul>
        </div>
      </div>
    </div>
  );
};

export default CombinedCareerOptions;
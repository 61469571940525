import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import Profile from "../Assets/Profile.jpeg";
import axios from "axios";
import { PacmanLoader } from "react-spinners";
import {
  Row,
  Button,
  VerticalSpacer,
  HorizontalSpacer,
} from "@cred/neopop-web/lib/components";
import CustomButton, {colorConfigThree, colorConfigTwo} from "../Assets/Button";
// import { colorPalette } from "@cred/neopop-web/lib/primitives";
import "./Home.css";

const Home = () => {
  const [formData, setFormData] = useState({
    userType: "",
    age: "",
    currentClass: "",
    stream: "",
    gender: "",
    category: "",
  });
  const [username, setUsername] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [showForm, setShowForm] = useState(false);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user && user.username) {
      setUsername(user.username);
    }
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const navigate = useNavigate();

  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    navigate("/");
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const classNum = parseInt(formData.currentClass);
    if (classNum < 1 || classNum > 12 || isNaN(classNum)) {
      alert("Class must be a number between 1 and 12.");
      return;
    }
    setIsLoading(true);
    try {
      const submissionData = {
        userType: formData.userType,
        age: parseInt(formData.age),
        currentClass: classNum,
        stream: formData.stream,
        gender: formData.gender,
        category: formData.category,
      };

      submissionData.stream = classNum < 11 ? "NA" : formData.stream;

      const response = await axios.post(
        "https://careerxpo.vercel.app/api/users/query",
        submissionData
      );

      if (response.status === 200) {
        setFormData({
          userType: "",
          age: "",
          currentClass: "",
          stream: "",
          gender: "",
          category: "",
        });
        
        if (classNum >= 9 && classNum <= 10) {
          navigate("/highschool");
        } else {
          navigate("/careeroptions", { state: { stream: formData.stream } });
        }
      } else {
        console.error("Server responded with an error:", response.data);
        alert(`Error: ${response.data.msg}`);
      }
    } catch (error) {
      console.error("Api error:", error);
      if (error.response) {
        alert(`Error: ${error.response.data.msg}`);
      } else if (error.request) {
        alert("No response received from server. Please try again.");
      } else {
        alert(`Error: ${error.message}`);
      }
    } finally {
      setIsLoading(false);
      setShowForm(false);
    }
  };


  return (
    <div>
      {username && <h2 className="welcome-message">Welcome, {username}!</h2>}
      <img src={Profile} alt="Profile" className="profile-pic" />
      <h1>Career Explorer</h1>
      <CustomButton
          variant="secondary"
          kind="elevated"
          size="big"
          colorConfig = {colorConfigThree}
          onClick={() => {
            setTimeout(() => {
              navigate("/profile"); // Navigate with a delay
            }, 300);
          }}
        >
          Profile
        </CustomButton>
        <HorizontalSpacer n = {5}/>
      <Row >
        <Button
          variant="secondary"
          kind="elevated"
          size="big"
          onClick={() => {
            setTimeout(() => {
              navigate("/highschool"); // Navigate with a delay
            }, 300);
          }}
        >
          9-10
        </Button>
        
      </Row>
      <HorizontalSpacer n={3} />
      <Row>
        <Button
          variant="secondary"
          kind="elevated"
          size="big"
          onClick={() => {
            setTimeout(() => {
              navigate("/careeroptions", { state: { stream: "Maths" } }); // Navigate with a delay
            }, 300);
          }}
        >
          Maths
        </Button>
        <VerticalSpacer n={2} />
        <Button
          variant="secondary"
          kind="elevated"
          size="big"
          onClick={() => {
            setTimeout(() => {
              navigate("/careeroptions", { state: { stream: "Arts" } }); // Navigate with a delay
            }, 300);
          }}
        >
          Arts
        </Button>
      </Row>
      <HorizontalSpacer n={3} />
      <Row>
        <Button
          variant="secondary"
          kind="elevated"
          size="big"
          onClick={() => {
            setTimeout(() => {
              navigate("/careeroptions", { state: { stream: "Biology" } }); // Navigate with a delay
            }, 300);
          }}
        >
          Biology
        </Button>
        <VerticalSpacer n={2} />
        <Button
          variant="secondary"
          kind="elevated"
          size="big"
          onClick={() => {
            setTimeout(() => {
              navigate("/careeroptions", { state: { stream: "Commerce" } }); // Navigate with a delay
            }, 300);
          }}
        >
          Commerce
        </Button>
      </Row>
      <HorizontalSpacer n={2} />
      <Row>
      <CustomButton
       
        onClick={() => {
          setTimeout(() => {
            setShowForm(!showForm);
          }, 400);
        }}
      >
        Search
      </CustomButton>

      {showForm && (
        <div className="popup-overlay">
          <div className="popup-content">
            <form
              id="profile-form"
              className="form-horizontal"
              onSubmit={handleSubmit}
            >
              <div className="form-group">
                <label htmlFor="userType">Are you a Parent or Student?</label>
                <select
                  id="userType"
                  name="userType"
                  value={formData.userType}
                  onChange={handleInputChange}
                  required
                >
                  <option value="" disabled>
                    Choose one
                  </option>
                  <option value="Student">Student</option>
                  <option value="Parent">Parent</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="age">Age</label>
                <input
                  type="number"
                  id="age"
                  name="age"
                  placeholder="Enter age"
                  min="1"
                  value={formData.age}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="currentClass">Current Class</label>
                <input
                  type="number"
                  id="currentClass"
                  name="currentClass"
                  placeholder="Enter class (1-12)"
                  min="1"
                  max="12"
                  value={formData.currentClass}
                  onChange={handleInputChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="stream">Stream/Subject</label>
                <select
                  id="stream"
                  name="stream"
                  value={formData.stream}
                  onChange={handleInputChange}
                  required
                >
                  <option value="" disabled>
                    Choose one
                  </option>
                  <option value="Maths">Maths</option>
                  <option value="Biology">Biology</option>
                  <option value="Commerce">Commerce</option>
                  <option value="Arts">Arts</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="gender">Gender</label>
                <select
                  id="gender"
                  name="gender"
                  value={formData.gender}
                  onChange={handleInputChange}
                  required
                >
                  <option value="" disabled>
                    Choose one
                  </option>
                  <option value="Male">Male</option>
                  <option value="Female">Female</option>
                  <option value="Other">Other</option>
                </select>
              </div>
              <div className="form-group">
                <label htmlFor="category">Category</label>
                <select
                  id="category"
                  name="category"
                  value={formData.category}
                  onChange={handleInputChange}
                  required
                >
                  <option value="" disabled>
                    Choose one
                  </option>
                  <option value="General">General</option>
                  <option value="OBC">OBC</option>
                  <option value="SC">SC</option>
                  <option value="ST">ST</option>
                </select>
              </div>
              <Row className="v-center">
                <Button
                  variant="Secondary"
                  kind="flat"
                  size="big"
                  colorMode="dark"
                  colorPalette="#F08D32"
                  onClick={() => {
                    setTimeout(() => {
                      handleSubmit();
                    }, 300);
                  }}
                >
                  Submit
                </Button>
                <VerticalSpacer n={3} />
                <Button
                  variant="primary"
                  kind="flat"
                  size="big"
                  colorMode="dark"
                  type = "back"
                  onClick={() => {
                      setShowForm(false);
                  }}
                >
                  Close
                </Button>
              </Row>
            </form>
          </div>
        </div>
      )}
      <VerticalSpacer n = {3}/>
      <CustomButton
        colorConfig = {colorConfigTwo}
        onClick={() => {
          setTimeout(() => {
            handleLogout();
          }, 400);
        }}
      >
        Log out
      </CustomButton>
    </Row>
      {isLoading && (
        <div className="loading-overlay">
          <PacmanLoader color="#ff7300" loading={isLoading} size={25} />
        </div>
      )}
    </div>
  );
};

export default Home;

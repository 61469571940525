import React, { useState } from "react";
import axios from "axios";
import { PacmanLoader } from "react-spinners";
import {
  Row,
  Button,
  VerticalSpacer,
  HorizontalSpacer,
} from "@cred/neopop-web/lib/components";
import { useNavigate } from "react-router-dom";
import CustomButton from "../Assets/Button";
import "./Style.css";

export const Header = ({ subject }) => {
  const navigate = useNavigate();
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };
  const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("token");
    navigate("/");
  };

  return (
    <div className="header">
      <button className="sidebar-toggle" onClick={toggleSidebar}>
        ☰
      </button>
        <h1 className="header-title">Career Options After {subject}</h1>
      <div className={`sidebar ${isSidebarOpen ? 'open' : ''}`}>
        <button className="close-sidebar" onClick={toggleSidebar}>
          &times;
        </button>
        <Row className="v-center">
                <Button
                  variant="Secondary"
                  kind="elevated"
                  size="big"
                  colorMode="dark"
                  colorPalette="#F08D32"
                  onClick={() => {
                    setTimeout(() => {
                      navigate('/profile')
                    }, 300);
                  }}
                >
                  Profile
                </Button>
                <VerticalSpacer n={3} />
                <Button
                  variant="primary"
                  kind="elevated"
                  size="big"
                  colorMode="dark"
                  type = "back"
                  onClick={() => {
                    setTimeout(() => {
                      handleLogout();
                    }, 300);
                  }}
                >
                  Log out
                </Button>
              </Row>
      </div>
    </div>
  );
};

export const Footer = () => {
  const [isFormOpen, setIsFormOpen] = useState(false);
  const [message, setMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const toggleForm = () => setIsFormOpen(!isFormOpen);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsLoading(true);
    try {
      const submissionData = {
        message: message,
      };

      const response = await axios.post(
        "https://careerxpo.vercel.app/api/users/feedback",
        submissionData
      );
      console.log("Response:", response.data);
      // alert("Feedback submitted successfully!");
      setMessage("");
      setIsFormOpen(false);
    } catch (error) {
      console.error("Error submitting feedback:", error);
      alert("Failed to submit feedback. Please try again.");
    } finally {
      setIsLoading(false); // Stop loading regardless of outcome
    }
  };

  return (
    <>
      <div className="footer">
        <p>
          © 2024 Bro ❤️. All rights reserved.{" "}
          <span className="beta-label" title="This is in beta currently">
            beta
          </span>
        </p>
      </div>

      <div className="feedback-button">
        <CustomButton
          variant="secondary"
          kind="elevated"
          size="small"
          onClick={() => {
            setTimeout(() => {
              toggleForm();
            }, 300);
          }}
        >
          Feedback
        </CustomButton>
      </div>
      {isFormOpen && (
        <div className="modal-overlay">
          <div className="feedback-form">
            <button onClick={toggleForm} className="close-button">
              X
            </button>
            <h2>Feedback/Query</h2>
            <form onSubmit={handleSubmit}>
              <textarea
                value={message}
                onChange={(e) => setMessage(e.target.value)}
                placeholder="Enter your feedback/query"
                required
              />
              <button type="submit" className="submit-button">
                Submit
              </button>
            </form>
          </div>
          {/* Loading overlay */}
          {isLoading && (
            <div
              style={{
                position: "fixed",
                top: 0,
                left: 0,
                width: "100%",
                height: "100%",
                backgroundColor: "transparent",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                zIndex: 9999,
              }}
            >
              <PacmanLoader color="#ff7300" loading={isLoading} size={25} />
            </div>
          )}
        </div>
      )}
    </>
  );
};

import React, { useState, useEffect } from "react";
import axios from "axios";
import { HashLoader } from "react-spinners";
import "./Profile.css";

const Profile = () => {
  const [userData, setUserData] = useState({
    fullName: "",
    username: "",
    mobile: "",
    email: "",
    studentClass: "",
    schoolId: "",
    city: "",
  });

  const [loading, setLoading] = useState(true);
  const [message, setMessage] = useState("");

  useEffect(() => {
    // Fetch user data on component mount
    const fetchData = async () => {
      try {
        const response = await axios.get(
          "https://careerxpo.vercel.app/api/users/profile",
          {
            headers: {
              Authorization: `Bearer ${localStorage.getItem("token")}`,
            },
          }
        );
        setUserData(response.data);
        setLoading(false);
      } catch (err) {
        console.error(err);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserData({ ...userData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.put(
        "https://careerxpo.vercel.app/api/users/update-profile",
        userData,
        {
          headers: { Authorization: `Bearer ${localStorage.getItem("token")}` },
        }
      );
      if (response.status === 200) {
        setMessage("Profile updated successfully!");
      }
    } catch (err) {
      console.error(err);
      setMessage("Failed to update profile.");
    }
  };

  return (
    <div className="profile-container">
      <h2> Profile</h2>
      <form onSubmit={handleSubmit}>
        <div className="profile-fields">
          <label>Full Name</label>
          <input
            type="text"
            name="fullName"
            value={userData.fullName}
            onChange={handleChange}
          />
          <label>Username</label>
          <input
            type="text"
            name="username"
            value={userData.username}
            onChange={handleChange}
            disabled
          />
          <label>Mobile Number</label>
          <input
            type="text"
            name="mobile"
            minLength={10}
            maxLength={10}
            value={userData.mobile}
            onChange={handleChange}
            disabled
          />
          <label>Email</label>
          <input
            type="text"
            name="email"
            value={userData.email}
            onChange={handleChange}
            disabled
          />
          <label>Student's Class</label>
          <input
            type="tel"
            name="studentClass"
            min={1}
            max={12}
            value={userData.studentClass}
            onChange={handleChange}
            required
          />
          <label>School Name</label>
          <input
            type="text"
            name="schoolId"
            value={userData.schoolId}
            onChange={handleChange}
            required
          />

          <label>City</label>
          <input
            type="text"
            name="city"
            value={userData.city}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit">Update Profile</button>
      </form>
      {message && <p>{message}</p>}
      {loading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "transparent",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <HashLoader color="#ff8200" loading={loading} size={50} />
        </div>
      )}
    </div>
  );
};

export default Profile;

import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { extend, mainColors } from '@cred/neopop-web/lib/primitives';

import './index.css';

const container = document.getElementById('root');
const root = createRoot(container);

extend({
  mainColors: {
      black: '#000',
  },
  colorGuide: {
      lightComponents: {
          buttons: {
              primary: {
                  backgroundColor: mainColors.black,
              },
          },
      },
  },
});

root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>
);

import React, { useState , useEffect } from "react";
import "./Common/CareerOptions.css";
import { Button } from "@cred/neopop-web/lib/components";
import { Header } from "../components/headerFooter";
import { HashLoader } from "react-spinners";
import axios from "axios";

const CareerOptions = () => {
  const [selectedCareer, setSelectedCareer] = useState(null);
  const [careerData, setCareerData] = useState({});
  const [isLoading, setIsLoading] = useState(false);


  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true); // Start Loading
      try {
        const response = await axios.get(
          "https://careerxpo.vercel.app/api/users/highschool"
        );
        const fetchedData = response.data.reduce((acc, item) => {
          acc[item.title] = item;
          return acc;
        }, {});
        setCareerData(fetchedData);
      } catch (error) {
        console.error('Error fetching career data:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const handleCareerClick = (career) => {
    setSelectedCareer(career);
  };

  const careerOptions = Object.keys(careerData);

  return (
    <div className="career-options-container">
      <Header subject="Highschool" />
      <div className="main-content">
        {!selectedCareer ? (
          <div className="career-grid">
            {careerOptions.map((option, index) => (
              <Button
                variant="secondary"
                kind="elevated"
                size="big"
                key={index}
                onClick={() => {
                  setTimeout(() => {
                    handleCareerClick(option)
                  }, 300);
                }}
              >
                {option}
              </Button>
            ))}
          </div>
        ) : (
          <CareerDetails
            career={selectedCareer}
            data={careerData[selectedCareer]}
            onBack={() => setSelectedCareer(null)}
          />
        )}
      </div>
      {isLoading && (
        <div
          style={{
            position: "fixed",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            backgroundColor: "transparent",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 9999,
          }}
        >
          <HashLoader color="#ff8200" loading={isLoading} size={50} />
        </div>
      )}
    </div>
  );
};

const CareerDetails = ({ career, data, onBack }) => {
  return (
    <div className="career-details">
      <Button
        variant="secondary"
        kind="elevated"
        size="medium"
        onClick={() => {
          setTimeout(() => {
            onBack();
          }, 400);
        }}
      >
        &larr; Back to all careers
      </Button>
      <div className="career-card">
        <div className="career-card-header">
          <h2>{career}</h2>
        </div>
        <div className="career-card-content">
          <p>{data.description}</p>
          <hr />
          <h3>Key Details</h3>
          <ul>
            <li>
              <strong>Timeline:</strong> {data.timeline}
            </li>
          </ul>
          <hr />
          {data.skills && data.skills.length > 0 && (
            <>
              <h3>Skills</h3>
              <ul>
                {data.skills.map((skill, index) => (
                  <li key={index}>{skill}</li>
                ))}
              </ul>
              <hr />
            </>
          )}
          {data.tips && data.tips.length > 0 && (
            <>
              <h3>Tips</h3>
              <ul>
                {data.tips.map((tip, index) => (
                  <li key={index}>{tip}</li>
                ))}
              </ul>
              <hr />
            </>
          )}
          {data.careers && data.careers.length > 0 && (
            <>
              <h3>Careers</h3>
              <ul>
                {data.careers.map((career, index) => (
                  <li key={index}>{career}</li>
                ))}
              </ul>
              <hr />
            </>
          )}
        </div>
      </div>
    </div>
  );
};


export default CareerOptions;

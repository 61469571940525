import React from 'react';
import { Button } from '@cred/neopop-web/lib/components';
import { colorPalette, mainColors } from '@cred/neopop-web/lib/primitives';

const colorConfig = {
    backgroundColor: '#FF4500',
    color: mainColors.white,
    borderColor: '#E5FE40',
    edgeColors: { right: '#4FE3A3', bottom: '#06C270', top: 'transparent', left: 'transparent' },
    disabledColors: {
        backgroundColor: colorPalette.black[50],
        color: (mainColors.orange, 0.5),
        edgeColors: {
            top: 'transparent',
            left: 'transparent',
            right: colorPalette.white[70],
            bottom: colorPalette.white[50],
        },
    },
};

export const colorConfigTwo = {
    backgroundColor: '#144CC7',
    color: mainColors.white,
    borderColor: '#161616',
    edgeColors: { right: '#F2FF9F', bottom: '#A0B22D', top: 'transparent', left: 'transparent' },
    disabledColors: {
        backgroundColor: colorPalette.black[50],
        color: (mainColors.orange, 0.5),
        edgeColors: {
            top: 'transparent',
            left: 'transparent',
            right: colorPalette.white[70],
            bottom: colorPalette.white[50],
        },
    },
};

export const colorConfigThree = {
    backgroundColor: '#3BFFAD',
    color: mainColors.black,
    borderColor: '#161616',
    edgeColors: { right: '#FFEFC7', bottom: '#B38E30', top: 'transparent', left: 'transparent' },
    disabledColors: {
        backgroundColor: colorPalette.black[50],
        color: (mainColors.orange, 0.5),
        edgeColors: {
            top: 'transparent',
            left: 'transparent',
            right: colorPalette.white[70],
            bottom: colorPalette.white[50],
        },
    },
};

const CustomButton = (args) => {
    return (
        <Button
            size="big"
            colorMode="dark"
            variant="secondary"
            kind="elevated"
            colorConfig={colorConfig}
            {...args}
        >
            
        </Button>
    );
};

export default CustomButton;